export const orientationData = [
  {
    title: "Any Orientation",
  },
  {
    title: "Landscape",
    value: "landscape",
  },
  {
    title: "Portrait",
    value: "portrait",
  },
  {
    title: "Square",
    value: "squarish",
  },
];

export const sortData = [
  {
    title: "Relevance",
    value: "relevant",
  },
  {
    title: "Newest",
    value: "latest",
  },
];
